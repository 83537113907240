<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/home"
            >
              <span class="brand-logo">
                <b-img
                  :src="logoNetEye"
                  alt="NetEye"
                />
              </span>
              <h2 class="brand-text">
                NetEye
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="menuItens"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { mapActions, mapState } from 'vuex'
import { getUserData } from '@/auth/utils'
import menu from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      menuItens: [],
      items: [],
    }
  },
  computed: {
    ...mapState(['auth/user']),
  },

  async created() {
    this.user = await this.me()

    this.unsubscribe = this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === 'auth/SET_AUTH_USER') {
        this.user = state.auth.user
        this.items = await this.getMenuTree()
        this.buildMenu()
      }
    })
    this.items = await this.getMenuTree()
    this.buildMenu()
  },
  methods: {
    ...mapActions('auth', ['me', 'getMenuTree']),
    buildMenu() {
      const navMenu = []
      const user = getUserData()
      if (!user) {
        this.$router.push({ name: 'login' })
      }
      const buildMenuTree = item => {
        const currentItem = { ...item }
        let hasSubmenus = item.children
        if (item.children) {
          currentItem.children = []
          const submenus = []

          for (const menuItem of item.children) {
            if (menuItem.profile.some(profile => profile === user.type)) {
              submenus.push(menuItem)
            }
          }

          hasSubmenus = submenus.length > 0
          if (hasSubmenus) {
            currentItem.children = submenus
          }
        }

        if ((!item.route && hasSubmenus) || currentItem.profile.some(profile => profile === user.type)) {
          navMenu.push(currentItem)
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const item of menu) {
        buildMenuTree(item)
      }

      const userMenu = this.buildUserMenu()
      this.menuItens = userMenu.concat(navMenu)
    },

    buildUserMenu() {
      let items = []
      for (const item of this.items) {
        if (!item.active) {
          continue
        }
        if (item.show_title) {
          items.push({
            header: item.name,
            profile: ['super_admin', 'admin', 'user'],
          })
        }
        const menuTree = item.items.map(itemMenu => {
          const route = this.mapRoute(itemMenu)
          const menuItem = {
            title: itemMenu.name,
            route,
            icon: itemMenu.icon,
            profile: ['super_admin', 'admin'],
          }
          if (itemMenu.items.length > 0) {
            menuItem.route = ''
            menuItem.children = itemMenu.items.map(child => ({
              title: child.name,
              route: this.mapRoute(child),
              icon: child.icon,
              profile: ['super_admin', 'admin', 'user'],
            }))
          }
          return menuItem
        })
        items = items.concat(menuTree)
      }
      return items
    },
    mapRoute(item) {
      let route = ''
      if (item.page?.uuid !== undefined) {
        switch (item.page.type) {
          case 'report':
            route = { name: 'report.show', params: { pageId: item.uuid, reportId: item.page.url } }
            break
          case 'url':
            route = { name: 'page.render', params: { menuId: item.uuid, pageId: item.page.uuid } }
            break
          default:
            route = item.page.url
            break
        }
      }
      return route
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'MenuIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      logoNetEye: require('@/assets/images/logo/favico.png'),
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.font {
  &.apontamento {
    font-weight: 1000;
    font-family: 'Montserrat' !important
  }

  &.digital {
    font-weight: 100;
    font-family: 'Montserrat' !important
  }
}
</style>
