const menu = [
// :crud-operations://
  {
    header: 'Administração do Sistema',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Usuários',
    route: 'users.list',
    icon: 'UserIcon',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Páginas',
    route: 'pages.list',
    icon: 'GlobeIcon',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Menu',
    route: 'menu.list',
    icon: 'MenuIcon',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Perfis',
    route: 'profiles.list',
    icon: 'UsersIcon',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Clientes',
    route: 'clients.list',
    icon: 'ServerIcon',
    profile: ['super_admin', 'admin'],
  },
]

export default menu
